import React, {useEffect, useState,} from "react";
import Chart from "react-apexcharts";
import { Box, Card, CardHeader } from '@mui/material';
import axios from 'axios';

export default function AppExperienceSummary({ storeID }) {
  const [xAxisList, setXAxisList] = useState();
  const [fourStar, setFourStar] = useState();
  const [threeStar, setThreeStar] = useState();
  const [twoStar, setTwoStar] = useState();
  const [oneStar, setOneStar] = useState();
  const [avgExp, setAvgExp] = useState();

  useEffect(() => {
    try {
      axios.get(`https://client-prod-api.portal.ai.dev.uknomi.com/v1/stores/${storeID}/reports/ExperienceSummary`).then(
        response=>{
          const chartInfo = JSON.parse(JSON.stringify(response.data));
          chartInfo.forEach(chart => {
            setXAxisList(chart.WeekNum.split(","));
            setFourStar(chart.FourStar.split(","));
            setThreeStar(chart.ThreeStar.split(","));
            setTwoStar(chart.TwoStar.split(","));
            setOneStar(chart.OneStar.split(","));
            setAvgExp(chart.AvgExperience.split(","));
          });
        }
      );
    } catch (error) {
      console.log('BrandExperience failed!');
    }
  }, [])

  const options = {
    chart: {
      toolbar: {
        show: false,
      }, 
      type: 'line',
    },
    xaxis: {
      categories: xAxisList,
    },
    yaxis: [
      {
        seriesName: 'Star',
        title: {
          text: "Average Experience Rating"
        },
        min: 0,
        max: 5,
      },
    ]
  };
  const series = [
    {
      name: "4 ★'s",
      data: fourStar
    },
    {
      name: "3 ★'s",
      data: threeStar
    },
    {
      name: "2 ★'s",
      data: twoStar
    },
    {
      name: "1 ★'s",
      data: oneStar
    },
    {
      name: "Average",
      data: avgExp
    }
  ]
  return (
      options && series &&
      <Card>
          <CardHeader title="Experience Summary" />
          <Box sx={{ mx: 3 }} dir="ltr">
          <div className="app">
              <div className="row">
              <div className="mixed-chart">
                  <Chart
                  options={options}
                  series={series}
                  type="line"
                  width="90%"
                  />
              </div>
              </div>
          </div>
          </Box>
      </Card>
  )
}

import React, {useEffect, useState,} from "react";
import Chart from "react-apexcharts";
import { Box, Card, CardHeader } from '@mui/material';
import axios from 'axios';

export default function AppCustomerVisitFrequencyTrends({ storeID }) {
  const [xAxisList, setXAxisList] = useState();
  const [newCust, setNewCust] = useState();
  const [bronzeCust, setBronzeCust] = useState();
  const [goldCust, setGoldCust] = useState();
  const [silverCust, setSilverCust] = useState();
  const [options, setOptions] = useState();
  const [series, setSeries] = useState();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const url = `https://client-prod-api.portal.ai.dev.uknomi.com/v1/stores/${storeID}/reports/CustomerVisitFrequencyTrends`
    try {
      axios.get(url).then(
        response=>{
          const chartInfo = JSON.parse(JSON.stringify(response.data));
          chartInfo.forEach(chart => {
            setXAxisList(chart.WeekNum.split(","));
            if(chart.status === "New") {
              setNewCust(chart.status_values.split(","));
            }
            if(chart.status === "Bronze") {
              setBronzeCust(chart.status_values.split(","));
            }
            if(chart.status === "Silver") {
              setSilverCust(chart.status_values.split(","));
            }
            if(chart.status === "Gold") {
              setGoldCust(chart.status_values.split(","));
            }
          });
          setLoading(false);
        }
      );
    } catch (error) {
      console.log('GetLoyalty Insights failed!');
    }
    const aoptions = {
      chart: {
        type: 'basic-bar',
        height: 350,
        stacked: true,
      },
      xaxis: {
        categories: xAxisList,
      },
      yaxis: [
        {
          seriesName: 'Gold',
          title: {
            text: "Visits by Loyalty Tier (%)"
          },
          min: 1,
          max: 100,
        },
      ],
      colors: ['#87ceeb', '#cd7f32', '#d3d3d3', '#ffd700'  ],
    };
    const aseries = [
      {
        name: "New",
        data: newCust
      },
      {
        name: "Bronze",
        data: bronzeCust
      },
      {
        name: "Silver",
        data: silverCust
      },
      {
        name: "Gold",
        data: goldCust
      }
    ]
    setOptions(aoptions);
    setSeries(aseries);
    setLoading(false);
  }, [storeID]) 
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    options && series &&
      <Card>
          <CardHeader title="Visit Frequency by Loyal Tier Trends" />
          <Box sx={{ mx: 3 }} dir="ltr">
          <div className="app">
              <div className="row">
              <div className="mixed-chart">
                  <Chart
                  options={options}
                  series={series}
                  type="bar"
                  width="90%"
                  />
              </div>
              </div>
          </div>
          </Box>
      </Card>
  )
}

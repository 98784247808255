import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import { Box, Card, CardHeader } from '@mui/material';
import axios from 'axios';

export default function AppServiceTrends({ storeID }) {
  const [xAxisList, setXAxisList] = useState(null);
  const [avgOrderTime, setAvgOrderTime] = useState(null);
  const [avgPayTime, setAvgPayTime] = useState(null);
  const [avgPickupTime, setAvgPickupTime] = useState(null);
  const [options, setOptions] = useState();
  const [series, setSeries] = useState();
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    const url = `https://client-prod-api.portal.ai.dev.uknomi.com/v1/stores/${storeID}/reports/ServiceTrends`
    try {
      axios.get(url).then(
        response=>{
          const chartInfo = JSON.parse(JSON.stringify(response.data));
          const xAxis = [];
          const aOrderTime = [];
          const aPayTime = [];
          const aPickupTime = [];
          chartInfo.forEach(chart => {
            xAxis.push(chart.WeekNum);
            aOrderTime.push(chart.AvgOrderTime);
            aPayTime.push(chart.AvgPayTime);
            aPickupTime.push(chart.AvgPickupTime);
          });
          setXAxisList(xAxis);
          setAvgOrderTime(aOrderTime);
          setAvgPayTime(aPayTime);
          setAvgPickupTime(aPickupTime);
        }
      );
    } catch (error) {
      console.log('GetService Insight failed!');
    }
    const aoptions = {
      chart: {
        id: "basic-bar",
        height: 350,
        type: "bar",
        stacked: true
      },
      plotOptions: {
        bar: {
          dataLabels: {
            style: {
              fontSize: '128px', // Increase the font size here
            },
          },
        },
      },
      xaxis: {
        categories: xAxisList,
      },
      yaxis: [
        {
          seriesName: 'Service Times',
          title: {
            text: "Service Time (Sec)"
          }
        },
      ]
    };
    const aseries = [
      {
        name: "Avg. Order Time",
        type: 'column',
        data: avgOrderTime,
      },
      {
        name: "Avg. Pay Time",
        type: 'column',
        data: avgPayTime,
      },
      {
        name: "Avg. Pickup Time",
        type: 'column',
        data: avgPickupTime,
      }
    ]
    setOptions(aoptions);
    setSeries(aseries);
    setLoading(false);
  }, [storeID])

  if (isLoading) {
    return <div className="App">Loading...</div>;
  }

  return (
    options && series &&
      <Card>
          <CardHeader title="Service Trends" />
          <Box sx={{ mx: 3 }} dir="ltr">
          {xAxisList && avgPickupTime ? (
            <div className="app">
              <div className="row">
              <div className="mixed-chart">
                  <Chart
                  options={options}
                  series={series}
                  type="bar"
                  width="90%"
                  />
              </div>
              </div>
            </div>
          ) : (
            <div>Please choose a Store to load this metric...</div>
          )}
          </Box>
      </Card>
  )
}

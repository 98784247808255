import { Select, MenuItem } from '@mui/material';
import React, { useEffect, useState, } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function AppStoreList ({ onValueChange }) {
    const [storeData, setStoreData] = useState();
    const navigate = useNavigate();

    const handleChange = (event) => {
      console.log("Allstores onChange", event);
      const storeID = event.target.value;
      onValueChange(storeID);
    };
    useEffect(() => {
      const token = localStorage.getItem('token')
      if(token == null) {
        navigate('/login', { replace: true });
      }
      try {
          axios.get('https://client-prod-api.portal.ai.dev.uknomi.com/v1/stores').then(
            response=>{
              setStoreData(response.data);
            }
          );
        } catch (error) {
          console.log('GetStores failed!');
        }
  }, [])
    return (
        <Select defaultValue={0} onChange={handleChange}>
            <MenuItem value={0}>All Stores</MenuItem>
            {storeData && storeData.map((site) => (
            <MenuItem value={site.id}>{site.name}</MenuItem>
            ))}
        </Select>
  );
}

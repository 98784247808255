import React, {useEffect, useState} from "react";
import Chart from "react-apexcharts";
import { Box, Card, CardHeader } from '@mui/material';
import axios from 'axios';

export default function AppTimeOfDayServiceInsights({ storeID }) {
  const [xAxisList, setXAxisList] = useState(null);
  const [avgServiceTime, setAvgServiceTime] = useState(null);

  let options
  let series
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
  const url = `https://client-prod-api.portal.ai.dev.uknomi.com/v1/stores/${storeID}/reports/TimeOfDayServiceInsights`
  try {
    axios.get(url).then(
      response=>{
        const chartInfo = JSON.parse(JSON.stringify(response.data));
        const xAxis = [];
        const aServiceTime = [];
        chartInfo.forEach(chart => {
          xAxis.push(chart.TimeOfDay);
          aServiceTime.push(chart.AvgServiceTime);
        });
        setXAxisList(xAxis);
        setAvgServiceTime(aServiceTime);
      }
    );
  } catch (error) {
    console.log('TimeOfDayServiceInsights failed!');
  }
  options = {
    chart: {
      id: "basic-bar",
      height: 350,
      type: "bar",
      stacked: false
    },
    xaxis: {
      categories: xAxisList,
    },
    yaxis: [
      {
        seriesName: 'Service Times',
        title: {
          text: "Avg Service Time (Sec)"
        }
      },
    ]
  };
  series = [
    {
      name: "Avg. Service Time",
      type: 'column',
      data: avgServiceTime,
    }
  ]
  setLoading(false);
}, [storeID])
  if (isLoading) {
    return <div className="App">Loading...</div>;
  }
  return (
    options && series &&
      <Card>
          <CardHeader title="Time of Day Service Insights (Last 8 Weeks)" />
          <Box sx={{ mx: 3 }} dir="ltr">
          {xAxisList && avgServiceTime ? (
            <div className="app">
              <div className="row">
              <div className="mixed-chart">
                  <Chart
                  options={options}
                  series={series}
                  type="bar"
                  width="90%"
                  />
              </div>
              </div>
            </div>
          ) : (
            <div>Please choose a Store to load this metric...</div>
          )}
          </Box>
      </Card>
  )
}

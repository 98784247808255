import React, {useEffect, useState} from "react";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';

const columns = [
{ id: 'vln', label: 'Customer (VLN)', minWidth: 80 },
{ id: 'time', label: 'Time\u00a0of\u00a0day', minWidth: 170 },
{ id: 'ordersize', label: 'Order\u00a0Size', minWidth: 80 },
{ id: 'ordertime', label: 'Order\u00a0time', minWidth: 80 },
{ id: 'paytime', label: 'Pay\u00a0time', minWidth: 80 },
{ id: 'picktime', label: 'Pick-up\u00a0time', minWidth: 80 },
{ id: 'servicetime', label: 'Service\u00a0Time', minWidth: 80 },
{ id: 'goal', label: 'Time\u00a0to\u00a0goal\u00a0(15\u00a0min)', minWidth: 80 },

];

export default function AppTransactionReport({storeID}) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rows, setRows] = useState(null);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    useEffect(() => {
        const url = `https://client-prod-api.portal.ai.dev.uknomi.com/v1/stores/${storeID}/reports/TransactionReport`
        try {
            const inrows = [];
            axios.get(url).then(
                response=>{
                    const chartInfo = JSON.parse(JSON.stringify(response.data));
                    chartInfo.forEach(chart => {
                        inrows.push({vln:chart.VLN, time:chart.TimeOfDay, ordersize:chart.OrderSize, ordertime:chart.OrderTime, paytime:chart.PayTime, picktime:chart.PickupTime, servicetime:(chart.OrderTime + chart.PayTime + chart.PayTime), goal:chart.TimeToGoal});
                    });
                    setRows(inrows);
                }
            );
        } catch (error) {
            console.log('TransactionReport failed!');
        }
    }, [storeID])
    return (
        <Card>
        <CardHeader title="Transaction Details" />
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
            <TableHead>
                <TableRow>
                {columns.map((column) => (
                    <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    >
                    {column.label}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {rows && rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                    return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                        {columns.map((column) => {
                        const value = row[column.id];
                        return (
                            <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                                ? column.format(value)
                                : value}
                            </TableCell>
                        );
                        })}
                    </TableRow>
                    );
                })}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows && rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        </Paper>
        </Card>
    );
}

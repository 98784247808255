import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography } from '@mui/material';

// sections
import {
  AppBrandLoyalty,
  AppBrandExperienceSummary,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function BrandMetricsPage() {
  const theme = useTheme();

  const [storeID, setStoreID] = useState();
  const token = localStorage.getItem('token')
  if(token != null) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  const handleStoreSelection = (newStoreID) => {
    setStoreID(newStoreID);
  };

  useEffect(() => {
    console.log("Dashboard Loaded");
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard | Uknomi Client Portal </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>

        <Grid container spacing={3}>

          <Grid item xs={6} md={6} lg={12}>
            <AppBrandLoyalty />
          </Grid>

          <Grid item xs={6} md={6} lg={12}>
            <AppBrandExperienceSummary />
          </Grid>
          
        </Grid>
      </Container>
    </>
  );
}

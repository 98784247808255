import React, {useEffect, useState,} from "react";
import Chart from "react-apexcharts";
import { Box, Card, CardHeader, TableContainer, Table, TableRow, TableBody, TableCell, TableHead, Paper } from '@mui/material';
import axios from 'axios';

export default function AppBrandExperienceSummary() {
  const [xAxisList, setXAxisList] = useState();
  const [fourStar, setFourStar] = useState();
  const [threeStar, setThreeStar] = useState();
  const [twoStar, setTwoStar] = useState();
  const [oneStar, setOneStar] = useState();
  const [avgExp, setAvgExp] = useState();

  useEffect(() => {
    try {
      axios.get('https://client-prod-api.portal.ai.dev.uknomi.com/v1/brand-reports/BrandExperienceSummary').then(
        response=>{
          const chartInfo = JSON.parse(JSON.stringify(response.data));
          chartInfo.forEach(chart => {
            setXAxisList(chart.WeekNum.split(","));
            setFourStar(chart.FourStar.split(","));
            setThreeStar(chart.ThreeStar.split(","));
            setTwoStar(chart.TwoStar.split(","));
            setOneStar(chart.OneStar.split(","));
            setAvgExp(chart.AvgExperience.split(","));
          });
        }
      );
    } catch (error) {
      console.log('BrandExperience failed!');
    }
  }, [])

  const options = {
    chart: {
      toolbar: {
        show: false,
      }, 
      type: 'line',
    },
    xaxis: {
      categories: xAxisList,
    },
    yaxis: [
      {
        seriesName: 'Star',
        title: {
          text: "Average Experience Rating"
        },
        min: 0,
        max: 5,
      },
    ]
  };
  const series = [
    {
      name: "4 ★'s",
      data: fourStar
    },
    {
      name: "3 ★'s",
      data: threeStar
    },
    {
      name: "2 ★'s",
      data: twoStar
    },
    {
      name: "1 ★'s",
      data: oneStar
    },
    {
      name: "Average",
      data: avgExp
    }
  ]
  return (
      options && series && xAxisList &&
      <Card>
          <CardHeader title="Experience Summary" />
          <Box sx={{ mx: 3 }} dir="ltr">
          <div className="app">
              <div className="row">
              <div className="mixed-chart">
                  <Chart
                  options={options}
                  series={series}
                  type="line"
                  width="90%"
                  />
              </div>
              </div>
          </div>
          </Box>
        <br/><br/><br/>
        <CardHeader title="Experience Summary Table (week by week)" />
        <Box>
          <TableContainer component={Paper}>
              <Table>
                <TableHead>
                    <TableRow>
                      <TableCell>Week</TableCell>
                      {xAxisList.map((col) => (
                          <TableCell>{col}</TableCell>
                      ))}
                      </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                    <TableCell>Avg. Rating</TableCell>
                    {avgExp.map((col) => (
                        <TableCell>{col}</TableCell>
                    ))}
                    </TableRow>
                    <TableRow>
                    <TableCell>Rating to Goal</TableCell>
                    {avgExp.map((col) => (
                        <TableCell>{col}</TableCell>
                    ))}
                    </TableRow>
                </TableBody>
                </Table>
            </TableContainer>
          </Box>
      </Card>
  )
}

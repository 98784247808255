import React, {useEffect, useState} from "react";
import { Box, Card, CardHeader, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import axios from 'axios';

export default function AppVisitSpendTrends({storeID}) {
    const [xAxisList, setXAxisList] = useState(null);
    const [avgSpend, setAvgSpend] = useState(null);
    const [totalOrders, setTotalOrders] = useState(null);
    const [totalSpend, setTotalSpend] = useState(null);

    useEffect(() => {
        const url = `https://client-prod-api.portal.ai.dev.uknomi.com/v1/stores/${storeID}/reports/VisitSpendTrends`
        try {
            axios.get(url).then(
                response=>{
                const chartInfo = JSON.parse(JSON.stringify(response.data));
                const xAxis = [];
                const aSpend = [];
                const tOrders = [];
                const tSpend = [];
                chartInfo.forEach(chart => {
                    xAxis.push(chart.WeekNum);
                    aSpend.push(chart.AvgSpend);
                    tOrders.push(chart.TotalOrders);
                    tSpend.push(chart.TotalSpend);
                });
                setXAxisList(xAxis);
                setAvgSpend(aSpend);
                setTotalOrders(tOrders);
                setTotalSpend(tSpend);
                }
            );
            } catch (error) {
            console.log('VisitSpendTrends failed!');
            }
    }, [storeID]);
        return (
        <Card>
            <CardHeader title="Visits and Spend Trends" />
            <Box sx={{ mx: 3 }} dir="ltr">
            {xAxisList && totalOrders && avgSpend ? (
            <div>
                <div className="app">
                <div className="row">
                <TableContainer component={Paper}>
                    <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell>Week</TableCell>
                        {xAxisList && xAxisList.map((col) => (
                            <TableCell>{col}</TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                        <TableCell>Total Number</TableCell>
                        {totalOrders.map((col) => (
                            <TableCell>{col}</TableCell>
                        ))}
                        </TableRow>
                        <TableRow>
                        <TableCell>Amount</TableCell>
                        {totalSpend.map((col) => (
                            <TableCell>${col}</TableCell>
                        ))}
                        </TableRow>
                        <TableRow>
                        <TableCell>Avg. Amount</TableCell>
                        {avgSpend.map((col) => (
                            <TableCell>${col}</TableCell>
                        ))}
                        </TableRow>
                    </TableBody>
                    </Table>
                </TableContainer>
                </div>
                </div>
            </div>
            ) : (
            <div>Please choose a Store to load this metric...</div>
            )}
            </Box>
        </Card>
    );
}

